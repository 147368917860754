import { forEach } from 'lodash'

import { getUserIP } from 'utils/api'
import { getCookie } from 'utils/client/cookie'

const { TEST_ORDER } = process.env.server

export default async function({ cart, sales, yandex }) {
  const { personal, userGA } = cart
  const products = []

  const fbp = getCookie('_fbp')
  const fbc = getCookie('_fbc')
  const client_user_agent = window.navigator.userAgent
  const { ip } = await getUserIP()

  forEach(cart.goods, (value, key) => {
    if (value.isSubscription && value.amount) {
      products.push({
        id: sales.goods[key].subscription.id,
        quantity: cart.goods[key].amount,
      })
    } else if (!value.isSubscription && value.amount) {
      products.push({
        id: sales.goods[key].id,
        quantity: cart.goods[key].amount,
      })
    }
  })

  return {
    products,
    contact_info: {
      name: personal.name,
      email: personal.email,
      phone_code: personal.phone_code,
      phone: personal.phone,
      country: personal.country,
    },
    delivery_info: {
      city: personal.city,
      address: personal.address,
      postcode: personal.postcode,
      metro: personal.metro,
      delivery_type: cart.deliveryType,
    },
    promo: cart.promo.errors?.errorCode ? '' : cart.promo.name,
    charge_info: {
      ...yandex,
      currency: sales.currency,
    },
    cookies: {
      SOURCE: getCookie('utm_source'),
      REFERRER: getCookie('REFERRER'),
      cl_uid: getCookie('cl_uid'),
    },
    is_test: TEST_ORDER,
    ga_cid: userGA,
    fbc,
    fbp,
    client_user_agent,
    client_ip_address: ip,
    check_cost: cart.sum.cost,
    unfinished_id: cart.orderIdUnfinished,
    is_offer_accepted: !!cart.personal.is_offer_accepted,
    is_marketing_accepted: !!cart.personal.is_marketing_accepted,
    is_research_accepted: !!cart.personal.is_research_accepted,
  }
}
